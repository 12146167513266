<template>
  <v-card flat>
    <v-card-title>
      <span style="color: #b31616; font-size: 1.5rem">Announcements</span>
      <v-spacer></v-spacer>

      <v-col cols="3">
        <v-switch
          v-model="showNotExpired"
          :label="showNotExpired ? 'Not Expired' : 'Expired'"
        ></v-switch>
      </v-col>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            outlined
            class="mt-0 mr-2"
            color="primary"
            @click="fetchAllAnnouncements()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <div v-for="announcement in getAllAnnouncements" :key="announcement.id">
        <announcement-card
          :announcement="announcement"
          :isExpiredView="showNotExpired"
        ></announcement-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import announcementCard from "./announcementcard.vue";
export default {
  name: "messagesTab",
  components: {
    announcementCard,
  },
  data: () => ({
    editDialog: false,
    deleteDialog: false,
    showNotExpired: true,
  }),
  computed: {
    ...mapGetters({
      getAllAnnouncements: "getAllAnnouncements",
    }),
  },
  methods: {
    ...mapActions({
      fetchAllAnnouncements: "fetchAllAnnouncements",
      fetchTopics: "fetchTopics",
    }),
  },
  mounted() {
    this.fetchTopics();
    this.fetchAllAnnouncements();
  },
};
</script>

<style scoped>
</style>