<template>
  <v-card>
    <v-card-title>
      <menuTitle :title="'Edit Announcement'"></menuTitle>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Expiration date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker color="primary" v-model="date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu.save(date);
                  announcement.expirationdate = date;
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            hide-details
            label="Title"
            v-model="announcement.announcementtitle"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="d-flex align-center">
          <v-select
            v-model="announcement.topicid"
            item-text="topictype"
            item-value="id"
            :items="getTopics"
            label="Topics"
            outlined
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea
            hide-details
            rows="1"
            auto-grow
            label="Text"
            v-model="announcement.announcementtext"
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined @click="$emit('done')">
        <span>Cancel</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="updateAnnouncement()">
        <span>Save</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "../../../helpers/menu_title.vue";

export default {
  data() {
    return {
      date: null,
      menu: false,
      announcement: null,
    };
  },
  components: {
    menuTitle,
  },
  props: {
    tmpAnnouncement: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      getTopics: "getTopics",
    }),
  },
  methods: {
    ...mapActions({
      fetchTopics: "fetchTopics",
      showSnackbar: "showSnackbar",
      patchAnnouncement: "patchAnnouncement",
    }),
    updateAnnouncement() {
      console.log(this.announcement);
      this.patchAnnouncement(this.announcement).then((error) => {
        if (error) {
          this.showSnackbar(
            "Announcement could not be updated. Error {0}".format(error)
          );
        } else {
          this.showSnackbar("Announcement has been updated");
          this.$emit("done");
        }
      });
    },
  },
  async mounted() {
    this.fetchTopics();
  },
  created() {
    this.announcement = JSON.parse(JSON.stringify(this.tmpAnnouncement));
    this.date = this.announcement.expirationdate.substring(0, 10);
  }
};
</script>

<style>
</style>