<template>
  <v-card
    v-if="
      (isExpiredView && timeTillExpiration(announcement) > 0) ||
      (!isExpiredView && timeTillExpiration(announcement) < 0)
    "
    class="mb-4"
  >
    <v-row class="pb-1">
      <v-col class="d-flex justify-center">
        <span v-if="isExpiredView"
          >Expires at
          {{ announcement.expirationdate.substring(0, 10) }}
        </span>
        <span v-else>Expired</span>
      </v-col>
      <v-col class="d-flex justify-center">
        <span>{{ getTopicById(announcement.topicid).topictype }}</span>
      </v-col>
      <v-col class="d-flex justify-center">
        <v-icon
          outlined
          color="primary"
          @click="
            deleteDialog = true;
            announcementid = announcement.id;
          "
        >
          mdi-delete-outline
        </v-icon>
        <v-icon
          v-if="isExpiredView"
          outlined
          color="primary"
          @click="editDialog = true"
        >
          mdi-cog-outline
        </v-icon>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <v-card-title>{{ announcement.announcementtitle }} </v-card-title>
        <v-card-text> {{ announcement.announcementtext }}</v-card-text>
      </v-col>
      <v-col cols="3">
        <announcement-image
          :imageData="announcement.image.data"
        ></announcement-image>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" max-width="1500" persistent>
      <annoucement-dialog
        v-if="editDialog"
        :announcement="announcement"
        @done="editDialog = false"
      ></annoucement-dialog>
    </v-dialog>

    <accept-dialog
      v-model="deleteDialog"
      v-if="deleteDialog"
      title="Delete Announcement?"
      text="Are you sure you want to delete this announcement?"
      @accepted="deleteOneAnnouncement(announcementid)"
    >
    </accept-dialog>
  </v-card>
</template>

<script>
import acceptDialog from "../../../helpers/generic_accept_dialog.vue";
import annoucementDialog from "../view/announcementsdialog.vue";
import announcementImage from "./announcementimage.vue";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "annoucementcard",
  data() {
    return {
      announcementid: null,
      editDialog: false,
      deleteDialog: false,
    };
  },
  components: {
    acceptDialog,
    annoucementDialog,
    announcementImage,
  },
  props: {
    isExpiredView: {
      type: Boolean,
      required: true,
    },
    announcement: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getTopicById: "getTopicById",
    }),
  },
  methods: {
    ...mapActions({
      deleteAnnouncement: "deleteAnnouncement",
      showSnackbar: "showSnackbar",
    }),
    timeTillExpiration(announcement) {
      return new Date(announcement.expirationdate).getTime() - Date.now();
    },
    deleteOneAnnouncement(id) {
      this.deleteAnnouncement(id).then((error) => {
        if (error) {
          this.showSnackbar(
            "Announcement could not be deleted. Error {0}".format(error)
          );
        } else {
          this.showSnackbar("Announcement has been deleted");
        }
        this.deleteDialog = false;
        this.announcementid = null;
      });
    },
  },
};
</script>

<style>
</style>